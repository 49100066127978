import React from 'react';
import './Resource.css';
import Collapsible from '../Collapsible/Collapsible';
import CreateRecord from "../Records/CreateRecord/CreateRecord";

function Resource (props) {
    console.log('===>LIVEOPS===> Resource.js:');

    const editorFields = props.editorFields;
    const endpoint = props.endpoint;
    const record = props.record;
    const resourceName = props.resourceName;
    const title = props.title;
    const toastSuccess = props.toastSuccess;
    const toastFailure = props.toastFailure;
    const viewerFields = props.viewerFields;

    const dataContent = <div>
            <div className="resource-title-container">
                {title}
            </div>
            <div className="resource-data-container">
                {
                    viewerFields.fields.map((field,index) => {

                        const key = field.dbField;

                        if(!field.isCollection){
                            const value = record[key] || '';

                            return (
                                <div key={index}>
                                    <div id={index} className="field-container">
                                        <input
                                            className="field-input"
                                            id={key}
                                            type={field.type}
                                            value={value}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            );

                        } else {
                            const relatedRecords = record[key] || [];

                            return <div id={index} className="field-container">
                                <div className="related-records-label">{field.label}</div>
                                {
                                    relatedRecords.map((relatedRecord, index) => {
                                        return <div className="related-records-container" key={index}>
                                            <div className="pill-view-only">
                                                <span className="resource-pill-title">{field.titleField}: </span>
                                                <span>{relatedRecord[field.titleField]}</span>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        }

                    })
                }
            </div>
        </div>

    const recordEditor = <CreateRecord
        fields={editorFields}
        existingRecord={record}
        editMode={true}
        recordType={resourceName}
        toastSuccess={toastSuccess}
        toastFailure={toastFailure}
        editorFields={editorFields}
        apiUrl={endpoint}
        openText='(edit)'
    />

    const deleteButton = <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
        </svg>
    </div>

    return <div>
        <Collapsible title={props.name} recordEditor={recordEditor} deleteButton={deleteButton}>            
            {dataContent}
        </Collapsible>
    </div>
}

export default Resource;