import React from 'react';
import './NavigationMenuItem.css';

function NavigationMenuItem(props) {
  const setLoading = props.setLoading;

  return <div className="navigation-menu-item" onClick={ 
          ()=> {
            setLoading(true);
            props.onClick(props.destinationPage);
          } 
  
         }>
        {props.label}
    </div>;
}

export default NavigationMenuItem;