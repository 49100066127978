import React from "react";
import Page from "../../Page/Page";
import HelpContent from "../../HelpContent/HelpContent";
import Insight from "../../Insight/Insight";

function InsightsPage(){
    const helpContent = <HelpContent></HelpContent>
    return <Page title="Insights" helpButton={helpContent}>
        <div>
            <Insight name="ORIGIN" runDate="RUN DATE" correlation="CORRELATION" withinTarget="PERCENT WITHIN TARGET" outcomeDelta="+0.2"/>
        </div>
        <div>
            <Insight name="from: Primary Campaign Skills Update " runDate="8/15/2024" correlation="0.88" withinTarget="87%" outcomeDelta="+0.2"/>
        </div>
        <div>
            <Insight name="from: Campaign Skills A/B Test" runDate="8/3/2024" correlation="0.88" withinTarget="87%" outcomeDelta="+0.-1"/>
        </div>
        <div>
            <Insight name="from: Campaign Skills A/B Test" runDate="7/3/2024" correlation="0.95" withinTarget="95%" outcomeDelta="+0.4"/>
        </div>
    </Page>
}

export default InsightsPage;