import React from "react";
import "./SkillGroupsPage.css";
import ResourcePage from "../../ResourcePage/ResourcePage";

function SkillGroupsPage(props) {
    console.log('===>LIVEOPS===> SkillGroupsPage.js:');
    console.log('props:', props);

    const endpoint = "https://liveops-api.azure-api.net/enterprise/v1/skill-groups"
    const toastSuccess = props.toastSuccess;
    const setLoading = props.setLoading;
    const toastFailure = props.toastFailure;

    const viewerFields = 
        {
            fields : [
                {
                    label : "Client",
                    dbField : "client",
                    type : "text",
                    isCollection : false,
                    required : true
                },
                {
                    label : "Name",
                    dbField : "skill_group_name",
                    type : "text",
                    isCollection : false,
                    required : false
                },
                {
                    label : "Skills",
                    dbField : "skills",
                    type: "text",
                    isCollection : true,
                    records : [],
                    options : [{"id":"1bb4c628-cb6e-4e4b-bc1f-ba3fcbee5de4","external_id":"21028751","name":"TS Life Email DLT"},{"id":"e2680a16-4085-4f0a-82da-2805740e67f2","external_id":"21028752","name":"TS Life Email 1"},{"id":"fb9d15ac-84f3-4cd2-9196-bd75520927b9","external_id":"21028803","name":"TS Life Email 1 to 1"}],
                    titleField: "name",
                    required: true
                }
            ]
        };

    const editorFields = {
            fields : [
                {
                    label : "Client",
                    dbField : "client",
                    type : "text",
                    isCollection : false,
                    required : true
                },
                {
                    label : "Name",
                    dbField : "skill_group_name",
                    type : "text",
                    isCollection : false,
                    required : false
                },
                {
                    label : "Skills",
                    dbField : "skills",
                    type: "text",
                    isCollection : true,
                    records : [],
                    options : [{"id":"1bb4c628-cb6e-4e4b-bc1f-ba3fcbee5de4","external_id":"21028751","name":"TS Life Email DLT"},{"id":"e2680a16-4085-4f0a-82da-2805740e67f2","external_id":"21028752","name":"TS Life Email 1"},{"id":"fb9d15ac-84f3-4cd2-9196-bd75520927b9","external_id":"21028803","name":"TS Life Email 1 to 1"}],
                    titleField: "name",
                    required: true,
                }
            ]
        };

    return <ResourcePage
        editorFields={editorFields}
        endpoint={endpoint}
        helpButtonContent="Skill groups allow you to easily organize collections of related skills. A skill group can represent a campaign, skill type, or any other category. Skill groups can be used both as a source of proficiency data and a destination for new proficiency scores."
        helpButtonImage={<img src="/skill_group_types_no_title.PNG" alt="Skill Groups" className="help-button-image"/>}
        helpButtonTitle="Skill Groups"
        resourceTitleField="skill_group_name"
        setLoading={setLoading}
        toastSuccess={toastSuccess}
        toastFailure={toastFailure}    
        viewerFields={viewerFields}>
    </ResourcePage>
}

export default SkillGroupsPage;