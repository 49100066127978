import React, {useState} from "react";
import "./HelpButton.css";
import ModalWindow from "../ModalWindow/ModalWindow";


function HelpButton(props) {
  const icon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="25" fill="deepskyblue" class="bi bi-question-diamond" viewBox="0 1 16 16">
    <path d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
  </svg>

  const [modalWindowClass, setModalWindowClass] = useState('window-close');
  const [modalClass, setModalClass] = useState('close');

  return <div className="help-button-container">
    <ModalWindow
          icon={icon} 
          modalWindowClass={modalWindowClass}
          setModalWindowClass={setModalWindowClass}
          modalClass={modalClass}
          setModalClass={setModalClass}
       >{props.children}</ModalWindow>
  </div>
}

export default HelpButton;