import React from "react";
import Modal from "../Modal/Modal";
import { useState } from "react";
import "./ModalWindow.css";

function ModalWindow(props) {
  const deleteButton = props.deleteButton || <span></span>

  const closeModal = () => {
    props.setModalWindowClass('window-close');
    props.setModalClass('close');
  }

  const openModal = () => {
    props.setModalWindowClass('window-open');
    props.setModalClass('open');
  }

  return (
    <span>
      <div className="modification-icon-slot">
        <button type="submit" className="open-button" onClick={openModal}>{props.icon}</button>
        <span className="modal-window-delete-button-container">{deleteButton}</span>
      </div>
      <Modal modalClass={props.modalClass}>
        <div className={props.modalWindowClass}>
          <div className="button-close-container">
            <button type="submit" className="button-close" onClick={closeModal}>X</button>
          </div>
          <div className="modal-window-content">
            {props.children}
          </div>
        </div>
      </Modal>
    </span>
  );
}

export default ModalWindow;