import React from "react";
import "./Modal.css";

function Modal(props) {
  return (
    <div className={props.modalClass}>
      {props.children}
    </div>
  );
}

export default Modal;