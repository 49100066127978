import React, { useState } from 'react';
import './FileUploader.css';
import Modal from '../Modal/Modal';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

function FileUploader() {
  const [file, setFile] = useState(null);
  const [fileDisplayName, setFileDisplayName] = useState('No file selected');
  const [resultMessage, setResultMessage] = useState('waiting-for-upload-message');
  const [failureMessageClass, setFailureMessageClass] = useState('waiting-for-upload-message');
  const [modalClass, setModalClass] = useState('close');
  const [uploadButtonClass, setUploadButtonClass] = useState('upload-button-closed');

  const endpoint = 'https://liveops-api.azure-api.net/RoutingAttributeCsvImporter/v1/importroutingattributes';

  const handleFileChange = (event) => {
    console.log('File selected: ' + event.target.files[0].name);
    setFileDisplayName(event.target.files[0].name);
    setFile(event.target.files[0]);
    setUploadButtonClass('upload-button-open');
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first!');
      return;
    }

    setModalClass('open');

    const formData = new FormData();
    formData.append('file', file);

    //Debugging...
    const fileContents = await file.text();

    try {
      const response = await fetch(endpoint, {
        method: 'PUT',
        body: fileContents,
        headers: {
          'content-type': 'text/plain',
          'ocp-apim-subscription-key': '6de6306dba924a1b8604cc93c3150c43'
        },
        mode: 'cors'
      });


      const json = await response.json();
      console.log('Response:', json);

      if (!response.ok) {
        console.error('Error:', response.statusText);
        alert('There was an error uploading the file. Please try again.');
        setFailureMessageClass('failure-message');
        setModalClass('close');
        return;
      }

    } catch(error) {
      console.error('Error:', error);
      alert('There was an error uploading the file. Please try again.');
      setFailureMessageClass('failure-message');
      setModalClass('close');
      return;
    }
    setResultMessage('accepted-message');
    setModalClass('close');
  };

  return (
    <div className="file-uploader">
      <div>
          <label htmlFor="file-upload" className="custom-file-upload">select file</label>
          <div className="file-name">{fileDisplayName}</div>
          <input id="file-upload" type="file" accept=".csv" onChange={handleFileChange} />
          <button className={uploadButtonClass} onClick={handleUpload}>begin upload</button>
      </div>
      <div className={resultMessage}>
        SUCCESS - Your file has been accepted! Results should appear in NICE CXOne within 3-5 minutes.
        </div>
      <div className={failureMessageClass}>
        FAILURE - There was an error uploading the file. Please try again.
      </div>
      <Modal modalClass={modalClass}>
        <div className="loading-animation-container">
          <LoadingAnimation/>
        </div>
      </Modal>
    </div>
  );
}

export default FileUploader;