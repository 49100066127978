import { OktaAuth } from '@okta/okta-auth-js'
import React from 'react';
import { useState, useEffect } from 'react';
import Modal from "../Modal/Modal";
import './Authenticator.css';

function Authenticator(props){
    const [authenticated, setAuthenticated] = useState(props.authenticated);

    const setLoading = props.setLoading;

    const appLogin = () => {
        //setLoading(true);
        fetch('/api/getenvironment').then(response => response.json()).then(data => {
            const issuerURI = data.OAUTH_ISSUER_URI;
            const clientId = data.OAUTH_CLIENT_ID;
            const redirectURI = data.CREATE_SESSION_FUNCTION_APP_URI;

            var config = {
              issuer: issuerURI,
              clientId: clientId,
              redirectUri: redirectURI,
              responseType: 'code',
              pkce: false
            };
            
            var authClient = new OktaAuth(config);
            
            authClient.start().then(function() {
              console.log('===> Okta Auth SDK has been initialized');
            }).catch(function(err) {
              console.error(err);
            });
  
            authClient.token.getWithRedirect({})
              .then(function(res) {
                var tokens = res.tokens;
                console.log('tokens',tokens);
              }).catch(function(err) {
                console.error('Error perorming redirect',err);
             });
        }).catch(error => {
          setLoading(false);
          console.error('Error fetching data:', error);
        });
    }
    
  let authContent;
  if(authenticated){
    authContent = <span></span>;
  } else {
    authContent = 
    <div className="grayedOutBackground">
        <Modal modalClass="window-open">
          <div className="window">
            <div className="window">
            <div className="button-close-container">
            </div>
            <div className="modal-window-content">
            <div className="login-box-header">
              <img src="https://liveops.com/wp-content/themes/liveops-refresh/assets/images/logo.svg" alt="logo" />
              <div className="title-text">PLATFORM SERVICES</div>
            </div>
              <button className="loginButton" onClick={appLogin}>
                Sign In
              </button>
            </div>
            </div>
          </div>
        </Modal>
        <img className="call-center-worker" src="https://liveops.com/wp-content/uploads/2024/03/hero-the-virtualflex-platform.png"></img>
        <div className="graphics-container">
          <img className="tech-lines" src="https://liveops.com/wp-content/themes/liveops-refresh/assets/images/bg-tech-lines.png"></img>
        </div>
        <div className="login-footer">Copyright © 2024 Liveops, Inc. All rights reserved.</div>
    </div>

  }

    return <div>{authContent}</div>
}

export default Authenticator;