import React from "react";
import "./HomePage.css";
import Page from "../../Page/Page";
import HelpContent from "../../HelpContent/HelpContent";

function HomePage(props) {
    const helpContent = <HelpContent imageUrl="wfaccelerator_diagram.png" title="About Liveops Workforce Accelerator" content="Welcome to Liveops Workforce Accelerator! Workforce Accelerator helps drive outcomes by accelerating the workforce intelligence lifecycle- from planning to execution to analysis."/>;

    return <Page title="Workforce Accelerator" helpButton={helpContent}>
        <div className="home-page">
            <div className="infographic-container">
                <p>Welcome to Liveops Workforce Accelerator!</p>
                <p>Workforce Accelerator helps drive customer outcomes by accelerating the workforce intelligence lifecycle- from planning to execution to analysis. Discover more <a href="https://cxstudio.liveops.com">here.</a></p>
                <img className="lifecycle-infographic" src="lifecycle-infographic.png" alt="lifecycle-infographic" />
            </div>
        </div>
    </Page>
}

export default HomePage;