import React from "react";
import "./ToolsPage.css";
import Page from '../../Page/Page';
import Tool from '../../Tool/Tool';
import FileUploader from '../../FileUploader/FileUploader';

function ToolsPage(props) {
    return <Page title="Tools">
            <div className="tools-page-title">{props.title}</div>
            <Tool title="Nice CXOne Routing Attribute Upload">
                <div id="file-uploader-container"><FileUploader/></div>  
            </Tool>
    </Page>;
}

export default ToolsPage