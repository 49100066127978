import React from 'react';
import './LogoutButton.css';

function LogoutButton(props) {
    const handleLogout = () => {
        localStorage.removeItem('liveopsApiToken');
        props.setAuthenticated(false);
        window.location.reload();
    }
  
    return <button className="logout-button" onClick={handleLogout}>Logout</button>;
}

export default LogoutButton;