import React from "react";
import "./Insight.css"

function Insight(props) {
    return <div className="insight">
        <div className="insight-data-point">{props.name}</div>
        <div className="insight-data-point">{props.runDate}</div>
        <div className="insight-data-point">{props.completionDate}</div>
        <div className="insight-data-point">{props.correlation}</div>
        <div className="insight-data-point">{props.withinTarget}</div>
        <div className="insight-data-point">{props.outcomeDelta}</div>
    </div>
}

export default Insight;