import React from "react";
import "./LineItem.css";

function LineItem(props) {
    return <div className="line-item">
            <div className="pill">
            <div className="pill-title-text">{props.name}</div>
            <div className="pill-content-section">{props.value}</div>
        </div>
    </div> 
}

export default LineItem;