import React from "react";
import "./ProficiencySource.css";
import Collapsible from "../Collapsible/Collapsible";
import LineItem from "../LineItem/LineItem";

function ProficiencySource(props) {
    let sources 
    if(props.sources) {
        sources = props.sources.map((source, index) => {
            return <LineItem name={source.name} value={source.value}/>
        })
    } else {
        sources = <div>No sources found</div>;
    }

    return <div className="source-group">
        <Collapsible title={props.name}>            
            {sources}
        </Collapsible>
    </div>

}

export default ProficiencySource;