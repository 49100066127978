import React from 'react';
import './LoadingAnimation.css';

function LoadingAnimation() {
  return (
    <div className="loading-animation">
        <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  );
}

export default LoadingAnimation;