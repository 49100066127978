import React from 'react';
import './WorkforceRulesPage.css';
import Page from '../../Page/Page';
import HelpContent from '../../HelpContent/HelpContent';
import {useState, useEffect} from 'react';
import WorkforceRule from '../../WorkforceRule/WorkforceRule';

function WorkforceRulesPage() {
  const helpContent = <HelpContent imageUrl="lifecycle-infographic.png" title="About Liveops Workforce Studio" content="Welcome to Liveops Workforce Studio! Workforce Studio helps drive customer outcomes by accelerating the workforce intelligence lifecycle- from planning to execution to analysis. Discover more here."/>;
  
  //create a "rules" state variable...
  const [rules, setRules] = useState([]);
  
  const authToken = localStorage.getItem('liveopsApiToken');
  
  useEffect(() => {
    const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${authToken}` };
    fetch('https://readworkforcerules.azurewebsites.net/api/read-workforce-rules', {headers : headers})
      .then(response => response.json())
      .then(data => { setRules(data.data); console.log('Data fetched:', data.data); })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return <Page title="Workforce Rules" helpButton={helpContent}>
    <div className="scrollable-container">
      {rules.map(rule => 
        <WorkforceRule key={rule.id} rule={rule}></WorkforceRule>
      )}
    </div>
  </Page>
}

export default WorkforceRulesPage;