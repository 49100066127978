import React from 'react';
import './NavigationMenu.css';
import NavigationMenuItem from './NavigationMenuItem/NavigationMenuItem';

function NavigationMenu(props) {
  return <div id="navigation-menu" >
    <NavigationMenuItem destinationPage="Home" onClick={props.onClick} label="HOME" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="Insights" onClick={props.onClick} label="Insights" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="DataSources" onClick={props.onClick} label="Proficiency Sources" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="SkillGroups" onClick={props.onClick} label="Skill Groups" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="Tools" onClick={props.onClick} label="Tools" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="WorkforceRules" onClick={props.onClick} label="Workforce Rules" setLoading={props.setLoading}/>
    <NavigationMenuItem destinationPage="WorkforceStrategies" onClick={props.onClick} label="Workforce Strategies" setLoading={props.setLoading}/>
  </div>;
}

export default NavigationMenu;