import React from "react";
import Page from "../Page/Page";
import HelpButton from "../HelpButton/HelpButton";
import { useState, useEffect } from "react";
import CreateRecord from "../Records/CreateRecord/CreateRecord";
import Resource from "../Resource/Resource";

 function ResourcePage(props) {
    console.log('===>LIVEOPS===> ResourcePage.js:');
    console.log('props:', props);

    // component properties...
    const editorFields = props.editorFields;
    const endpoint = props.endpoint;
    const helpButtonContent = props.helpButtonContent;
    const helpButtonImage = props.helpButtonImage;
    const helpButtonTitle = props.helpButtonTitle;
    const resourceTitleField = props.resourceTitleField;
    const resourceTypeName = props.resourceTypeName;
    const setLoading = props.setLoading;
    const toastSuccess = props.toastSuccess;
    const toastFailure = props.toastFailure;
    const viewerFields = props.viewerFields;

    const [refreshButtonClass,setRefreshButtonClass] = useState('show-refresh-button');
    const [resources, setResources] = useState([]);

    console.log('===>LIVEOPS===> Resource Type Name:', resourceTypeName);
    console.log('===>LIVEOPS===> Editor Fields:', editorFields);
    console.log('===>LIVEOPS===> Viewer Fields:', viewerFields);

    const helpButton = <HelpButton openText="?">
        <div className="record-page-help-title">{helpButtonTitle}</div>
        <div className="record-page-help-content">{helpButtonContent}</div>
        <div className="record-page-help-image-container">{helpButtonImage}</div>
    </HelpButton>;
    
    const authToken = localStorage.getItem('liveopsApiToken');

    useEffect(() => {
        const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${authToken}` };

        setRefreshButtonClass('hide-refresh-button');
        console.log('===LIVEOPS===> setLoading', setLoading);
        setLoading(true);

        fetch(endpoint, {headers : headers})
            .then(response => response.json())
            .then(data => { 
                    setLoading(false);
                    setResources(data.data);
                    console.log('===LIVEOPS===> SUCCESS: Fetched data:', data.data);
                    toastSuccess("SUCCESS",'Successfully loaded data', 5000);
                    setRefreshButtonClass('show-refresh-button');
                }
            )
            .catch(error =>{
                    setLoading(false);
                    console.error('===LIVEOPS===> ERROR: Failed to load data:', error);
                    toastFailure("ERROR","Failed to load data", 5000);
                    setRefreshButtonClass('show-refresh-button');
                }
            );
            
        
    },[]);

    const refreshPage = () => {
        const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${authToken}` };

        fetch(endpoint, {headers : headers})
            .then(response => response.json())
            .then(data => { 
                    setLoading(false);
                    setResources(data.data);
                    console.log('===LIVEOPS===> SUCCESS: Fetched data:', data.data);
                    toastSuccess("SUCCESS",'Successfully loaded data', 5000);
                    setRefreshButtonClass('show-refresh-button');
                }
            )
            .catch(error =>{
                    setLoading(false);
                    console.error('===LIVEOPS===> ERROR: Failed to load data:', error);
                    toastFailure("ERROR","Failed to load data", 5000);
                    setRefreshButtonClass('show-refresh-button');
                }
            );
        }

    if(resources.length === 0 || !viewerFields || !editorFields){
        return <div>Loading...</div>
    }

    return <Page helpButton={helpButton} id="record-page-page" title={resourceTypeName}>
        <div className="action-icons">
            <div className="action-icon">
                <button onClick={refreshPage} className={refreshButtonClass}>
                    <svg  xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="deepskyblue" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                    </svg>
                </button>
            </div>
            <div className="action-icon">
                <CreateRecord
                    editMode={false}
                    existingRecord={null}
                    recordType={resourceTypeName}
                    toastSuccess={toastSuccess}
                    toastFailure={toastFailure}
                    fields={editorFields}
                    apiUrl={endpoint}
                    openText={'New' + resourceTypeName}>
                </CreateRecord>
            </div>
        </div>
        <div className="scrollable-container">
            {resources.map(resource => <Resource
                name={resource[resourceTitleField]}
                editorFields={editorFields}
                endpoint={endpoint}
                record={resource}
                resourceName={resourceTypeName}
                title="Records"
                toastFailure={toastFailure}
                toastSuccess={toastSuccess}
                viewerFields={viewerFields}
            />)}
        </div>
    </Page>;
}

export default ResourcePage;