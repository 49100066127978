import React from 'react';
import './Workspace.css';
import ToolsPage from '../Pages/ToolsPage/ToolsPage';
import WorkforceRulesPage from '../Pages/WorkforceRulesPage/WorkforceRulesPage';
import HomePage from '../Pages/HomePage/HomePage';
import SkillGroupsPage from '../Pages/SkillGroupsPage/SkillGroupsPage';
import DataSourcesPage from '../Pages/DataSourcesPage/DataSourcesPage';
import HelpPage from '../Pages/HelpPage/HelpPage';
import ReportsPage from '../Pages/ReportsPage/ReportsPage';
import InsightsPage from '../Pages/InsightsPage/InsightsPage';
import WorkforceStrategiesPage from '../Pages/WorkforceStrategiesPage/WorkforceStrategiesPage';

function Workspace(props) {
    const setLoading = props.setLoading;
    const toastSuccess= props.toastSuccess;
    const toastFailure = props.toastFailure;

    if(props.currentPage === 'Tools') {
        return <div className="workspace">
                <ToolsPage setLoading={setLoading}></ToolsPage>
            </div>;
    } else if (props.currentPage === 'WorkforceRules') {
        return <div className='workspace'>
            <WorkforceRulesPage setLoading={setLoading}></WorkforceRulesPage>
        </div>;
    } else if (props.currentPage === 'Home'){
        return <div className='workspace' setLoading={setLoading}>
            <HomePage></HomePage>
        </div>;
    } else if (props.currentPage === 'SkillGroups'){
        return <div className="workspace">
                <SkillGroupsPage setLoading={setLoading} toastSuccess={toastSuccess} toastFailure={toastFailure}></SkillGroupsPage>
               </div>;
    } else if (props.currentPage === 'DataSources'){
        return <div className="workspace"><DataSourcesPage setLoading={setLoading}></DataSourcesPage></div>;
    } else if (props.currentPage === 'Help'){
        return <div className="workspace">
            <div><HelpPage setLoading={setLoading}></HelpPage></div>
        </div>;
    } else if (props.currentPage === 'Reports'){
        return <div className="workspace">
            <ReportsPage setLoading={setLoading}></ReportsPage>
        </div>;
    } else if (props.currentPage === 'Insights'){
        return <div className="workspace">
            <InsightsPage setLoading={setLoading}></InsightsPage>
        </div>;
    } else if (props.currentPage === 'WorkforceStrategies'){
        return <div className="workspace">
            <WorkforceStrategiesPage setLoading={setLoading}></WorkforceStrategiesPage>
        </div>;
    }
    else {
        setLoading(false);
        return <div className="workspace">
                <div>Page not found: {props.currentPage}</div>
            </div>;
    }
}

export default Workspace;