import React from 'react';
import './Footer.css';

function Footer() {
    return <div id="footer">
        Copyright © 2024 Liveops, Inc. All rights reserved.
    </div>;
}

export default Footer;
