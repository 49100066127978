import React from "react";
import "./ReportsPage.css";
import Page from "../../Page/Page";

function ReportsPage(props) {
    return <Page id="reports-page" title="Reports">
 
    </Page>;
}

export default ReportsPage;