import React from 'react';
import './Collapsible.css';
import { useState } from 'react';
import RecordEditMenu  from '../DropdownMenu/MenuImplementations/RecordEditMenu/RecordEditMenu';

function CollapsibleComponent(props) {
    const [ contentClass, setContentClass ] = useState('content-collapsed');
    const deleteButton = props.deleteButton;
    const [ expansionButtonClass, setExpansionButtonClass ] = useState('button-show');
    const [ collapseButtonClass, setCollapseButtonClass ] = useState('button-hide');
    
    const openComponent = () => { setContentClass('content-expanded'); setExpansionButtonClass('button-hide'); setCollapseButtonClass('button-show') };
    const closeComponent = () => { setContentClass('content-collapsed'); setExpansionButtonClass('button-show'); setCollapseButtonClass('button-hide') };

    const toggleComponent = () => {
        if(contentClass === 'content-collapsed'){
            openComponent();
        } else {
            closeComponent();
        }
    }

    const recordEditor = props.recordEditor;

    return <div className="collapsible-component">
        <div className="headline">
            <div className="always-visible"><span className="collapsible-title-link" onClick={toggleComponent}>{props.title}</span></div>
            <div className="dropdown">{recordEditor}</div>
        </div>
        <div className={contentClass}>
            {props.children}
        </div>
    </div>;
}

export default CollapsibleComponent