import React from "react";
import "./HelpContent.css";
import HelpButton from "../HelpButton/HelpButton";

function HelpContent(props) {
  return (
    <HelpButton openText="?">
        <div className="help-title">{props.title}</div>
        <div className="help-content">{props.content}</div>
        <div className="help-image-container"><img className="help-image" src={props.imageUrl}></img></div>
    </HelpButton>
  );
}

export default HelpContent;