import React from 'react';
import './CreateRecord.css';
import ModalWindow from '../../ModalWindow/ModalWindow';
import RecordList from '../RecordList/RecordList';
import { useState } from 'react';

function CreateRecord(props) {
    const apiToken = localStorage.getItem('liveopsApiToken');
    const [recordBody,setRecordBody] = useState({});
    const toastSuccess = props.toastSuccess;
    const toastFailure = props.toastFailure;
    const fields = props.fields;
    const apiUrl = props.apiUrl;
    const existingRecord = props.existingRecord;
    const recordType = props.recordType;

    const editMode = props.editMode;

    let httpMethod = 'PUT';
    if(editMode){
        httpMethod = 'POST';
    }

    const [modalWindowClass, setModalWindowClass] = useState('window-close');
    const [modalClass, setModalClass] = useState('close');

    const deleteRecord = async () => {
        const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${apiToken}` };

        try {
            console.log('===>LIVEOPS===> Deleting record:', recordBody);
            console.log('===>LIVEOPS===> API URL:', apiUrl);

            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: headers,
                body: JSON.stringify(recordBody)
            });

            console.log('===LIVEOPS===> Record deletion response:', response);

            toastSuccess('SUCCESS','Record has been deleted.', 5000);
        } catch (error) {
            toastFailure('ERROR','Failed to delete record', 5000);
            console.error('===>LIVEOPS===> Error:', error);
        }
    }

    const validateRecord = () => {
        console.log('===>LIVEOPS===> Validating record:', recordBody);

        let isValid = true;
        for (const key in recordBody) {
            console.log('checked key:' +  key + ' value:' + recordBody[key]);

            if (recordBody[key] === '' || recordBody[key] === undefined || recordBody[key] === null || recordBody[key] == []) {
                isValid = false;
                break;
            }
        }

        if(!isValid){
            toastFailure('ERROR','Please fill in all fields', 5000);
        } else {
            toastSuccess('SUCCESS','Record is valid.', 5000);
            //submitRecord();
        }
    }

    const submitRecord = async () => {
        const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${apiToken}` };

        try {
            console.log('===>LIVEOPS===> Creating new record:', recordBody);
            console.log('===>LIVEOPS===> API URL:', apiUrl);

            const response = await fetch(apiUrl, {
                method: httpMethod,
                headers: headers,
                body: JSON.stringify(recordBody)
            });
            
            console.log('===LIVEOPS===> Record creation response:', response);

            toastSuccess('SUCCESS','Record has been saved.', 5000);
            
            //setModalWindowClass('window-close');
            //setModalClass('close');
        } catch (error) {
            toastFailure('ERROR','Failed to create new record', 5000);
            console.error('===>LIVEOPS===> Error:', error);
            
            //setModalWindowClass('window-close');
            //setModalClass('close');
        }
    }

    let icon = <span></span>;
    let deleteButton = <span></span>;
    let title = <span></span>;

    if(editMode){
        icon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="action-icon" fill="deepskyblue" class="bi bi-pencil-square" viewBox="0 0 16 16">
            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
            <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
        </svg>

        deleteButton = <button onClick={deleteRecord}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="deepskyblue" class="bi bi-trash" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                     </svg>
                </button>;
        title = <span className="text__large">Edit {recordType}</span>;
    } else {
        icon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="action-icon" fill="deepskyblue" class="bi bi-plus-square" viewBox="0 0 16 16">
        <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
      </svg>

        title = <span className="text__large">Create {recordType}</span>;
    }

    const updateRecordBodyList = (key,value) => {
        setRecordBody({...recordBody,[key]:value});
    }

    return (
        <div className="create-record">
            <ModalWindow 
                icon={icon}
                deleteButton = {deleteButton}
                setModalWindowClass={setModalWindowClass}
                modalWindowClass={modalWindowClass}
                setModalClass={setModalClass}
                modalClass={modalClass}
            >
                <div className="create-record-modal-content">
                    <div className="create-record-title-container">
                        {title}
                    </div>
                    <div className="create-record-field-content">
                        {
                            fields.fields.map((field,index) => {

                                let requiredIndicator = <span></span>;
                                if(field.required){
                                    requiredIndicator = <span className="required-indicator">*</span>;
                                }

                                const key = field.dbField;

                                if(!field.isCollection){
                                    recordBody[key] = recordBody[key] || '';

                                    let value = recordBody[key] || '';

                                    if (editMode && !recordBody[key]) {
                                        value = existingRecord[key];
                                        recordBody[key] = existingRecord[key];
                                    }

                                    return (
                                        <div key={index}>
                                            <div id={index} className="field-container">
                                                <div className="create-record-field-label">{requiredIndicator}{field.label}</div>
                                                <input
                                                    className="field-input"
                                                    id={key}
                                                    type={field.type}
                                                    onChange={(e) => updateRecordBodyList(key, e.target.value)}
                                                    value={value}
                                                />
                                            </div>
                                        </div>
                                    );

                                } else {
                                    let records = [];
                                    recordBody[key] = recordBody[key] || [];
                                    
                                    if(editMode){
                                        records = existingRecord[key];
                                        recordBody[key] = existingRecord[key] || [];
                                    }

                                    return <div id={index} className="field-container">
                                             <RecordList titleField={field.titleField} id={key} requiredIndicator={requiredIndicator} name={field.label} records={records} selectableRecords={field.options} dbField={field.dbField} updateRecordBodyList={updateRecordBodyList}/>
                                           </div>
                                }

                            })
                        }
                    </div>
                </div>
                <div className="create-record-submit-button-container">
                        <button className="create-record-submit-button" onClick={validateRecord}>Save Record</button>
                </div>
            </ModalWindow>
        </div>
    );
}

export default CreateRecord;