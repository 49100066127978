import React from 'react';
import './Logo.css';

function Logo() {
  return <div id="logo">
    <img src="https://liveops.com/wp-content/themes/liveops-refresh/assets/images/logo.svg" alt="logo" />
    <div><span className="logo-purple-text">WF</span><span className="logo-gray-text">ACCELERATOR</span></div>
  </div>;
}

export default Logo;