import React, { useState } from 'react';
import './HelpPage.css';
import Page from '../../Page/Page';
import Collapsible from '../../Collapsible/Collapsible';
import LineItem from '../../LineItem/LineItem';
import TwoColumnLayout from '../../ContentLayouts/TwoColumnLayout/TwoColumnLayout';

function HelpPage(props) {
    return <Page id="help-page" title="Help">
        <div className="help-page-content">
            <Collapsible title="How to use this application">
                <TwoColumnLayout leftColumn={ <LineItem name="Step 1" value="Placeholder content"></LineItem> } rightColumn={<LineItem name="Step 2" value="Placeholder content"></LineItem>}>
                </TwoColumnLayout>
            </Collapsible>
            <Collapsible title="How to use this application">
                <p>Placeholder content</p>
            </Collapsible>
            <Collapsible title="How to use this application">
                <p>Placeholder content</p>
            </Collapsible>
        </div>      
    </Page>;
}

export default HelpPage;