import React from 'react';
import './WorkforceStrategiesPage.css';
import Page from '../../Page/Page';
import HelpContent from '../../HelpContent/HelpContent';
import {useState, useEffect} from 'react';

function WorkforceStrategiesPage() {
  const helpContent = <HelpContent imageUrl="lifecycle-infographic.png" title="About Liveops Workforce Studio" content="Welcome to Liveops Workforce Studio! Workforce Studio helps drive customer outcomes by accelerating the workforce intelligence lifecycle- from planning to execution to analysis. Discover more here."/>;
  
  const [strategies, setStrategies] = useState([]);
  const authToken = localStorage.getItem('liveopsApiToken');

  useEffect(() => {
    const headers = { 'Content-Type': 'application/json', 'Authorization' : `Bearer ${authToken}` };
    fetch('https://liveops-api.azure-api.net/enterprise/v1/workforce-strategies', {headers : headers})
    .then(response => response.json())
    .then(data => { setStrategies(data.data); console.log('Data fetched:', data.data); })
    .catch(error => console.error('Error fetching data:', error));
}, []);

  return <Page title="Workforce Strategies" helpButton={helpContent}>
    <div className="scrollable-container">
      {strategies.map(strategy => <div key={strategy.id}>{strategy.name}</div>)}
    </div>
  </Page>
}

export default WorkforceStrategiesPage;