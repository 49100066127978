import React from 'react';
import Header from './components/Header/Header';
import NavigationMenu from './components/NavigationMenu/NavigationMenu';
import Footer from './components/Footer/Footer';
import Workspace from './components/Workspace/Workspace';
import Authenticator from './components/Authenticator/Authenticator';
import LoadingAnimation from './components/LoadingAnimation/LoadingAnimation';
import './App.css';
import { useState } from 'react';
import ToastSuccess from './components/Toasts/ToastSuccess/ToastSuccess';
import ToastFailure from './components/Toasts/ToastFailure/ToastFailure';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  //TODO: Move the name of the token to an environment variable...
  //Look for the "liveops_api_token" in local storage...
  const liveopsApiToken = localStorage.getItem('liveopsApiToken');
  
  if(liveopsApiToken && authenticated === false){
    setAuthenticated(true);
  }

  const [ currentPage, setCurrentPage ] = useState('Home');
  const [ loading, setLoading ] = useState(false);

  let loadingWrapperClass = loading ? 'grayed-out-background' : 'hidden';

  //Success toast...
  const [isToastSuccessVisible, setIsToastSuccessVisible] = useState(false);
  const [toastSuccessMessage,setToastSuccessMessage] = useState('');
  const [toastSuccessTitle,setToastSuccessTitle] = useState('');

  const toastSuccess = (toastTitle, toastMessage,timeToClose) => {
      setToastSuccessTitle(toastTitle);
      setToastSuccessMessage(toastMessage);
      setIsToastSuccessVisible(true);
  
      setTimeout(() => {
        setIsToastSuccessVisible(false);
        setToastSuccessMessage('');
      }, timeToClose);
  }

  //Failure toast...
  const [isToastFailureVisible, setIsToastFailureVisible] = useState(false);
  const [toastFailureMessage,setToastFailureMessage] = useState('');
  const [toastFailureTitle,setToastFailureTitle] = useState('');

  const toastFailure = (toastTitle,toastMessage, timeToClose) => {
    setToastFailureTitle(toastTitle);
    setToastFailureMessage(toastMessage);
    setIsToastFailureVisible(true);

    setTimeout(() => {
      setIsToastFailureVisible(false);
      setToastFailureMessage('');
    }, timeToClose);
  }

  return (
    <div id="app">
      <div className="toast-container">
        <ToastSuccess title={toastSuccessTitle} message={toastSuccessMessage} setIsToastSuccessVisible={setIsToastSuccessVisible} isToastSuccessVisible={isToastSuccessVisible} toastSuccess={toastSuccess}/>
        <ToastFailure tite={toastFailureTitle} message={toastFailureMessage} setIsToastFailureVisible={setIsToastFailureVisible} isToastFailureVisible={isToastFailureVisible} toastFailure={toastFailure}/>
      </div>

      <div id="loading-wrapper" className={loadingWrapperClass}>
        <div id="loading-animation-wrapper">
          <LoadingAnimation/>
        </div>   
      </div>
      <Header setAuthenticated={setAuthenticated}/>
      <div className="content-wrapper">
        <div className="column">
          <NavigationMenu onClick={setCurrentPage} setLoading={setLoading}/>
        </div>
        <div className="column-wide">
          <Workspace currentPage={currentPage} setLoading={setLoading} toastSuccess={toastSuccess} toastFailure={toastFailure}/>
          <Authenticator authenticated={authenticated} setLoading={setLoading}/>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
