import React from 'react';
import Logo from '../Logo/Logo';
import './Header.css';
import ClientSelector from '../ClientSelector/ClientSelector';
import LogoutButton from '../LogoutButton/LogoutButton';

function Header(props) {
  const setAuthenticated = props.setAuthenticated;

  return <div id="header">
      <div className="logo-container">
        <Logo/>
      </div>
      <div className="client-selector">
        <ClientSelector/>
      </div>
      <div className="logout-button-container">
        <LogoutButton setAuthenticated={setAuthenticated}/>
      </div>
    </div>;
}

export default Header;