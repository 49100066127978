import React from 'react';
import './RecordDetail.css';

function RecordDetail(props) {
    const titleField = props.titleField;
    const record = props.record;
    const recordKeys = Object.keys(record);

    const removeRecordFromList = props.removeRecordFromList;
    
    const removeRecord = () => {
        removeRecordFromList(record.id);
    };

    return <div className="record-detail">
        {
            recordKeys.map(key => {
                if(key === titleField) {
                    return <div className="pill">
                        <div className="pill-content-section">
                            <div className="record-detail-content-section">
                                <div><span className="pill-title-text">{key}:</span> {record[key]}</div>
                            </div>
                            <div className="record-detail-button-section">
                                <div className="record-detail-remove-button" onClick={removeRecord}>X</div>
                            </div>
                        </div>
                    </div>
                };  
            })
        }
    </div>
}

export default RecordDetail;