import React from 'react';
import './Tool.css';

function Tool({ children, ...props }) {
  return (
    <div className="tool">
      <h3 className="title">{props.title}</h3>
      {children}
    </div>
  );
}

export default Tool;