import React from 'react';
import './Page.css';

function Page(props) {
    const helpButton = props.helpButton ? props.helpButton : null;
    return <div className="page">
        <div className="help-button-container">{helpButton}</div>
        <div className="page-title">{props.title}</div>
        {props.children}
    </div>;
}

export default Page;