import React from 'react';
import './TwoColumnLayout.css';

function TwoColumnLayout(props) {
    return <div className="two-column-layout">
        <div className="left-column">
            {props.leftColumn}
        </div>
        <div className="right-column">
            {props.rightColumn}
        </div>
    </div>;
}

export default TwoColumnLayout;