import React from "react";
import "./WorkforceRule.css";
import Collapsible from "../Collapsible/Collapsible";
import Icon from "../Icon/Icon";
import { useState, useEffect } from "react";

function WorkforceRule(props) {
    const [rule,setRule] = useState(props.rule);

    return <div className="workforce-rule">
                <Collapsible title={rule.name}>
                    <div className="workforce-rule-content">
                        <div className="workforce-rule-content-item">
                            <Icon name="proficiency source" imageUrl={props.strategyImageUrl}></Icon>
                            <div className="workforce-rule-content-item-label">Workforce Strategy</div>
                            <div className="workforce-rule-content-item-value">
                                <div className="sub-item">
                                    {rule.workforce_strategy_id}
                                </div> 
                            </div>
                        </div>
                        <div className="workforce-rule-content-item">
                            <Icon name="schedule" imageUrl="alarm.svg"></Icon>
                            <div className="workforce-rule-content-item-label">Frequency</div>
                            <div className="workforce-rule-content-item-value">
                                <div className="sub-item">
                                    {rule.frequency}
                                </div>
                            </div>
                        </div>
                        <div className="workforce-rule-content-item">
                            <Icon name="proficiency source" imageUrl="database.svg"></Icon>
                            <div className="workforce-rule-content-item-label">Proficiency Source</div>
                            <div className="workforce-rule-content-item-value">
                                <div>
                                    {rule.proficiency_sources.map(proficiencySource => <div className="sub-item" key={proficiencySource}>{proficiencySource}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="workforce-rule-content-item">
                            <Icon name="proficiency source" imageUrl="crosshair.svg"></Icon>
                            <div className="workforce-rule-content-item-label">Target Skill Groups</div>
                            <div className="workforce-rule-content-item-value">
                                <div>
                                    {rule.target_skill_groups.map(skillGroup => <div className="sub-item" key={skillGroup}>{skillGroup}</div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container"><button className="run-now-button">Run Now</button></div>
                </Collapsible>
            </div>
}

export default WorkforceRule;