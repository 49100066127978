import React from 'react';
import './ToastFailure.css';

function ToastFailure(props) {
    const title = props.title;
    const isToastFailureVisible = props.isToastFailureVisible;
    const setIsToastFailureVisible = props.setIsToastFailureVisible;

    let toastClass = 'toast-failure--show';

    if(isToastFailureVisible) {
        toastClass = 'toast-failure--show';
    } else {
        toastClass = 'toast-failure--hide';
    }

    const closeToast = () => {
        setIsToastFailureVisible(false);
    }

    return (
        <div className={toastClass}>
            <div className="title-bar">
                <div className="toast-upper-left"></div>
                <div className="toast-failure__title">{title}</div>
                <div className="toast-close-button" onClick={closeToast}>X</div>
            </div>      
            <div className="toast-failure__message">
                <span>{props.message}</span>
            </div>
        </div>
    );
}

export default ToastFailure;