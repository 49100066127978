import React from 'react';
import './RecordList.css';
import RecordDetail from '../RecordDetail/RecordDetail';
import RecordSelector from '../RecordSelector/RecordSelector';
import { useState } from 'react';

function RecordList(props) {
    const titleField = props.titleField;

    const updateRecordBodyList = props.updateRecordBodyList;

    const [records, setRecords] = useState(props.records);

    const selectableRecords = props.selectableRecords;

    const removeRecordFromList = (id) => {
        const filteredRecords = records.filter(record => record.id !== id);
        setRecords(filteredRecords);
    };

    const selectRecordFromPicklist = (fieldName,record) => {
        //Check for duplicates...
        if (records.find(r => r.id === record.id)) {
            return;
        }

        setRecords([...records, record]);
        updateRecordBodyList(fieldName,records);
    }

    return <div className="record-list">
        <div className="record-list-title">{props.requiredIndicator}{props.name}</div>
        <RecordSelector records={selectableRecords} selectRecord={selectRecordFromPicklist} dbField={props.dbField} name={`Add ${props.name}`}/>
        <div className="record-list-divider">
            {records.map(record => {
                return <div className="record" key={record.id}>
                    <RecordDetail titleField={titleField} removeRecordFromList={removeRecordFromList} record={record}/>
                </div>
            })}
        </div>
    </div>
}

export default RecordList;