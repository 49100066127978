import React from 'react';
import './DataSourcesPage.css';
import Page from '../../Page/Page';
import HelpContent from '../../HelpContent/HelpContent';
import ProficiencySource from '../../ProficiencySource/ProficiencySource';

function DataSourcesPage(props) {
    const helpContent = <HelpContent imageUrl="proficiency_sources_diagram.png" title="About Proficiency Sources" content="Proficiency sources are collections of call data that are used to predict agents' skill proficiency. A proficiency source can represent call data from a single campaign or be aggregated from multiple campaigns. Proficiency sources may also be configured with a call 'threshold' that an individual agent must exceed within the given timeframe, below which they will be assigned a 'default' proficiency level."/>;

    return <Page id="data-sources-page" title="Proficiency Sources" helpButton={helpContent}>
        <ProficiencySource name="APC :: TruStage FG-ConSales-2QWLANB :: Last 60 Days"
                    sources={[
                        { name: "campaign", value: "TruStage FG-ConSales-2QWLANB" },
                        { name: "timeframe", value: "Last 60 Days" },
                        { name: "minimum call threshold", value: "10" }
                    ]}
        ></ProficiencySource>
        <ProficiencySource name="APC :: TruStage FG-ConSales-AD&D :: Last 60 Days" sources={[
                        { name: "campaign", value: "TruStage FG-ConSales-AD&D" },
                        { name: "timeframe", value: "Last 60 Days" },
                        { name: "minimum call threshold", value: "10" }
                    ]}>
        </ProficiencySource>
        <ProficiencySource name="APC :: TruStage FG-ConSales-AD&D + TruStage FG-ConSales-2QWLANB + TruStage FG-ConSales-CU Print Material :: Last 60 Days"></ProficiencySource>
        <ProficiencySource name="APC :: TruStage FG-ConSales-CU Print Material :: Last 60 Days"></ProficiencySource>
        <ProficiencySource name="APC :: TruStage FG-ConSales-DigitalMarketing :: Last 60 Days"></ProficiencySource>
        <ProficiencySource name="APC :: TruStage FG-ConSales-LAU Inbound + TruStage FG-ConSales-DigitalMarketing :: Last 60 Days"></ProficiencySource>
    </Page>;
}

export default DataSourcesPage;