import React from 'react';
import './RecordSelector.css';

function RecordSelector(props) {
    const dbField = props.dbField;
    const records = props.records;
    const selectRecord = props.selectRecord;

    const [currentRecord, setCurrentRecord] = React.useState(records[0]);

    return (
        <div className="record-selector">
            <div className="record-selector-section">
                <div className="record-selector-column">
                    <div className="record-selector-dropdown-container">
                        <select onChange={(e) => setCurrentRecord(records.find(record => record.id === e.target.value))} className="record-selector-dropdown">
                            {records.map(record => (
                                <option key={record.id} value={record.id} className="record-selector-option">
                                    {record.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="record-selector-column-right">
                    <div className = "record-selector-button-container">
                        <button onClick={() => selectRecord(dbField,currentRecord)} className="record-selector-button">Add Related Record</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecordSelector;