import React from 'react';
import './ClientSelector.css';

function ClientSelector() {
  return (
    <div className="client-selector">
        <img className="client-logo" src="https://www.investopedia.com/thmb/ZGqJao0FjiATGvSq2yuuXtw6oRA=/1460x320/filters:no_upscale():max_bytes(150000):strip_icc()/TruStage-e6e25385d9e34e16aceb03a6b16b98cf.jpg"></img>
    </div>
  );
}

export default ClientSelector;